// you can control category order and unit display order here
const units = [
  { name: 'kmh', display: 'km/h', category: 'speed', multiplier: (x) => x },
  { name: 'mph', display: 'mph', category: 'speed', multiplier: (x) => x / 1.6 },
  { name: 'kts', display: 'knots', category: 'speed', multiplier: (x) => x / 1.852 },
  { name: 'c', display: '°C', category: 'temperature', multiplier: (x) => x },
  {
    name: 'f',
    display: '°F',
    category: 'temperature',
    multiplier: (x) => (9 / 5) * x + 32,
  },
  { name: 'm', display: 'm', category: 'height', multiplier: (x) => x },
  { name: 'ft', display: 'ft', category: 'height', multiplier: (x) => x * 3.28084 },
];
export default units;
