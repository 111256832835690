import React from 'react';
import { Box, Grid, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import StyledIcon from '../StyledIcon.jsx';
import mapLayers from '../../mapLayers.js';
import VariableInsightBottom from './VariableInsightsBottom.jsx';
import VariableInsightsDisplay from './VariableInsightsDisplay.jsx';

import {
  formatAMPM,
  formatAMPMwithMinutes,
  getUnitMultiplier,
  formatNumberGenerator,
  daysOfTheWeekShortName,
} from '../../utilities.js';

import clonedeep from 'lodash.clonedeep';

export default function VariableInsights({
  selectedVariableName,
  scrollTime,
  varData,
  liveData,
  variableInsights,
  formatHourMin,
  settings,
  selectedStation,
  tideStationMetadata,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { displayName, icon, unit } = mapLayers.find(
    (e) => e.variableName === selectedVariableName
  );

  const scrollDate = new Date(scrollTime);
  const insightsData = variableInsights[selectedVariableName];

  const { multiplier, displayUnits } = getUnitMultiplier(unit, settings.units);

  const formatNumber = formatNumberGenerator(multiplier);

  const yyymmdd =
    scrollDate.getFullYear() +
    '-' +
    ('0' + (scrollDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + scrollDate.getDate()).slice(-2);

  let currentInsightsData;

  if (selectedVariableName === 'seaSurfaceHeight' || selectedVariableName === 'current') {
    currentInsightsData = insightsData?.filter((d) => +new Date(d.date) > scrollDate);
  } else if (
    selectedVariableName === 'airTemperature' ||
    selectedVariableName === 'potentialTemperature'
  ) {
    currentInsightsData = insightsData?.find((d) => d?.date === yyymmdd);
  }

  const variableData = clonedeep(varData);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '500px',
        height: mobileView ? null : '100%',
        border: `2px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '15px',
        position: 'relative',
        padding: '5px 10px',
      }}
      color="secondary"
    >
      {
        <>
          <Box
            sx={{
              position: 'absolute',
              bottom: '-11px',
              left: `calc(50% - 9px)`,
              pointerEvents: 'none',
              zIndex: 100,
              width: 0,
              height: 0,
              borderLeft: `10px solid transparent`,
              borderRight: `10px solid transparent`,
              borderTop: `10px solid ${theme.palette.secondary.main}`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '-8px',
              left: `calc(50% - 7px)`,
              pointerEvents: 'none',
              zIndex: 100,
              width: 0,
              height: 0,
              borderLeft: `8px solid transparent`,
              borderRight: `8px solid transparent`,
              borderTop: `8px solid ${theme.palette.primary.main}`,
            }}
          />
        </>
      }
      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Grid container direction="row" justifyContent="space-around">
          <Grid item xs={4}>
            <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="center">
              <StyledIcon filename={icon}></StyledIcon>
              <Typography textAlign="center">{displayName}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="center">
              <Typography
                sx={{ textAlign: 'center', height: '41px' }}
                variant={
                  variableData?.value === null || variableData?.value === undefined
                    ? 'h5'
                    : 'h4'
                }
              >
                {liveData?.isLive === true && +liveData?.date === +scrollDate
                  ? formatNumber(liveData?.value)
                  : formatNumber(variableData?.value)}
              </Typography>
              <Typography sx={{ textAlign: 'center', height: '24px' }}>
                {variableData?.value === undefined || variableData?.value === null
                  ? ''
                  : displayUnits}
              </Typography>
            </Stack>
          </Grid>
          <Grid item container direction="row" spacing={2} xs={4} sx={{ height: '95px' }}>
            <VariableInsightsDisplay
              variableData={variableData}
              selectedVariableName={selectedVariableName}
              formatNumber={formatNumber}
              unit={displayUnits}
              currentInsightsData={currentInsightsData}
              liveData={liveData}
              scrollDate={scrollDate}
              tideStationMetadata={tideStationMetadata}
            />
          </Grid>
        </Grid>
        {(selectedVariableName === 'seaSurfaceHeight' ||
          (selectedVariableName === 'wave' && !selectedStation) ||
          (selectedVariableName === 'current' && selectedStation?.id)) && (
          <Stack paddingBottom="10px">
            <VariableInsightBottom
              formatNumber={formatNumber}
              variableData={variableData}
              currentInsightsData={currentInsightsData}
              selectedVariableName={selectedVariableName}
              selectedStation={selectedStation}
              unit={displayUnits}
              formatHourMin={formatHourMin}
              settings={settings}
            />
          </Stack>
        )}
        <Typography sc={{ width: '100%' }} textAlign="center" variant="body">
          {+liveData?.date === +scrollDate
            ? formatAMPMwithMinutes(scrollDate)
            : formatAMPM(scrollDate)}
          {`, ${daysOfTheWeekShortName[scrollDate.getDay()]}, ${scrollDate.getDate()}`}
        </Typography>
      </Stack>
    </Box>
  );
}
