import { Button, styled } from '@mui/material';
import { useWindowSize } from '../utilities';

export const StyledDrawerButton = styled(Button)(({ theme }) => ({
  height: useWindowSize().height < 700 ? '80px' : '100px',
  width: '100%',
  backgroundColor: theme.palette.primary.dark,
  color: 'white',
  borderRadius: '0px',
  border: `2px solid ${theme.palette.primary.dark}`,
  '&.MuiButtonBase-root:hover': {
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  padding: '0px',
}));
