import React from 'react';

import {
  Box,
  Typography,
  Grid,
  useTheme,
  Stack,
  IconButton,
  CircularProgress,
} from '@mui/material';
import mapLayers from '../../mapLayers.js';
import { colorIsDark, roundNumber } from '../../utilities';

import { ChevronRight, ChevronLeft, ArrowUpward, Circle } from '@mui/icons-material';

import { StyledDrawerButton } from '../StyledComponents.jsx';
import StyledIcon from '../StyledIcon.jsx';
import TideIndicator from '../TideIndicator.jsx';

export default function ExpandedDrawer({
  dataColor,
  value,
  formatNumber,
  displayUnits,
  direction,
  arrowDirection,
  moveSelectedVariable,
  drawerData,
  hasData,
  setDataPanelOpen,
  selectedVariable,
  selectedVariableName,
  period,
  gust,
  change,
  high,
  low,
  setSelectedVariableName,
  barHeight,
  loadingDrawerData,
  tideData,
}) {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Grid item xs={2} justifyContent="center">
          <StyledDrawerButton
            color="primary"
            onClick={() => {
              window.clarity('event', 'Expanded Drawer change variable');
              moveSelectedVariable('right');
            }}
            disabled={drawerData?.length === 1}
          >
            {<ChevronLeft color="secondary" />}
          </StyledDrawerButton>
        </Grid>
        <Grid item xs={8}>
          <StyledDrawerButton
            color="primary"
            onClick={() => {
              if (hasData) {
                window.clarity('event', 'Charts from expanded drawer');
                setDataPanelOpen(true);
              }
            }}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <Stack alignItems="center">
                  <StyledIcon filename={selectedVariable.icon} />
                  <Typography variant="overline" sx={{ wordBreak: 'break-word' }}>
                    {selectedVariable?.displayName?.slice(0, 8)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                {loadingDrawerData ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    variant={typeof value === 'number' && !isNaN(value) ? 'h4' : 'h5'}
                    color={dataColor}
                    sx={{
                      textShadow: colorIsDark(dataColor)
                        ? `0px 0px 2px white, 0px 0px 3px ${theme.palette.secondary.main}, 0px 0px 5px ${theme.palette.secondary.main}`
                        : '',
                    }}
                    textTransform="none"
                  >
                    {formatNumber(value)}
                  </Typography>
                )}
                <Box sx={{ textTransform: 'none' }}>
                  {value !== undefined && value !== null ? displayUnits : ''}
                </Box>
              </Grid>
              <Grid item xs={4}>
                {direction != null &&
                  typeof direction === 'number' &&
                  arrowDirection != null &&
                  typeof arrowDirection === 'number' && (
                    <Stack spacing="none" justifyContent="center" alignItems="center">
                      {arrowDirection != null && typeof arrowDirection === 'number' ? (
                        <ArrowUpward
                          color="secondary"
                          sx={{
                            transform: `rotate(${arrowDirection}deg)`,
                            width: '40px',
                            height: '40px',
                          }}
                        />
                      ) : (
                        '--'
                      )}
                      {selectedVariableName === 'wave' && period && (
                        <Box>
                          <hr style={{ width: '40px' }} />
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                          >
                            <Typography variant="body">{period.toFixed(0)}</Typography>
                            <Typography variant="caption" sx={{ textTransform: 'none' }}>
                              s
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                      {selectedVariableName === 'wind' && gust && (
                        <Box>
                          <hr style={{ width: '40px' }} />
                          <Stack direction="row" alignItems="center" spacing={0.2}>
                            <Typography variant="body">{formatNumber(gust)}</Typography>
                            <Typography variant="caption" sx={{ textTransform: 'none' }}>
                              {displayUnits}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                    </Stack>
                  )}
                {selectedVariableName === 'seaSurfaceHeight' &&
                  change !== null &&
                  typeof change === 'string' && (
                    <Stack
                      direction="row"
                      spacing="none"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack justifyContent="center" alignItems="center" spacing={0.5}>
                        {
                          <TideIndicator
                            minTide={drawerData.seaSurfaceHeight.min_height}
                            maxTide={drawerData.seaSurfaceHeight.max_height}
                            currentTide={drawerData.seaSurfaceHeight.value}
                          />
                        }
                        <Typography variant="caption">{change}</Typography>
                      </Stack>
                    </Stack>
                  )}
                {selectedVariableName?.includes('Temperature') &&
                  value !== undefined &&
                  value !== null && (
                    <Stack alignItems="center">
                      {roundNumber(high)}
                      {displayUnits}
                      <hr style={{ width: '20px' }} />
                      {roundNumber(low)}
                      {displayUnits}
                    </Stack>
                  )}
              </Grid>
            </Grid>
          </StyledDrawerButton>
        </Grid>
        <Grid item xs={2} justifyContent="center">
          <StyledDrawerButton
            color="primary"
            onClick={() => {
              window.clarity('event', 'Expanded Drawer change variable');
              moveSelectedVariable('left');
            }}
            disabled={drawerData?.length === 1}
            elevation={5}
          >
            {<ChevronRight color="secondary" />}
          </StyledDrawerButton>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '5px', height: `${barHeight}px` }}
      >
        {mapLayers.map((variable, index) => {
          return (
            <IconButton
              key={index}
              onClick={() => setSelectedVariableName(variable.variableName)}
              title={variable.displayName}
            >
              <Circle
                style={{
                  color:
                    selectedVariableName === variable.variableName
                      ? theme.palette.secondary.main
                      : theme.palette.primary.light,
                }}
                size="small"
              />
            </IconButton>
          );
        })}
      </Stack>
    </>
  );
}
