import React from 'react';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import stationTypes from '../stations';
import StyledIcon from './StyledIcon.jsx';
import StationsHiddenIcon from '../Images/Stations_hidden.png';

export default function StationsMenu({
  stationMenuOpen,
  setStationMenuOpen,
  showStationType,
  setShowStationType,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      fullScreen={mobileView}
      open={stationMenuOpen}
      sx={{ pointerEvents: 'auto' }}
      onClose={(e, reason) => reason === 'backdropClick' && setStationMenuOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      }}
    >
      <Toolbar>
        <Typography
          color="secondary"
          sx={{ ml: 2, flex: 1 }}
          variant="h6"
          component="div"
        >
          Show Station Type
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setStationMenuOpen(false)}
          aria-label="close"
        >
          <CloseIcon color="secondary" />
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ margin: '15px 25px', minWidth: mobileView ? '' : '400px' }}>
        {stationTypes.map((station, index) => {
          return (
            <React.Fragment key={index}>
              <ListItemButton
                onClick={() => {
                  window.clarity('event', 'Stations -> ' + station.typeName);
                  setShowStationType(showStationType === station ? undefined : station);
                  setStationMenuOpen(false);
                }}
                sx={{
                  border: `2px solid ${
                    showStationType === station ? 'white' : theme.palette.primary.main
                  }`,
                  borderRadius: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <ListItemText>{station.displayName}</ListItemText>
                <ListItemIcon>
                  <Box
                    sx={{
                      height: '42px',
                      width: '42px',
                      borderRadius: '30px',
                      border: `3px solid ${theme.palette.secondary.main}`,
                      margin: 'auto',
                      paddingTop: '4px',
                      paddingLeft: '0px',
                    }}
                  >
                    <StyledIcon filename={station.icon} height={30} width={38} />
                  </Box>
                </ListItemIcon>
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        })}
        <ListItemButton
          onClick={() => {
            setShowStationType();
            setStationMenuOpen(false);
          }}
        >
          <ListItemText>None - Hide all stations</ListItemText>
          <ListItemIcon sx={{ width: '44px', height: '44px', paddingLeft: '4px' }}>
            <Box component="img" src={StationsHiddenIcon} />
          </ListItemIcon>
        </ListItemButton>
      </List>
    </Dialog>
  );
}
