import React from 'react';

import { Box, Typography, useTheme, Stack, CircularProgress } from '@mui/material';

import { ArrowUpward } from '@mui/icons-material';
import { colorIsDark } from '../../utilities';

export default function MiniDrawer({
  setDrawerCollapsed,
  dataColor,
  value,
  formatNumber,
  displayUnits,
  direction,
  arrowDirection,
  loadingDrawerData,
}) {
  const theme = useTheme();
  return (
    <Box onClick={() => setDrawerCollapsed(false)} sx={{ minWidth: '90px' }}>
      <Stack direction="row" spacing={1}>
        <Stack direction="row" spacing={0.1}>
          {loadingDrawerData ? (
            <CircularProgress />
          ) : (
            <Typography
              variant={typeof value === 'number' && !isNaN(value) ? 'h4' : 'h5'}
              color={dataColor}
              sx={{
                textShadow: colorIsDark(dataColor)
                  ? `0px 0px 2px white, 0px 0px 3px ${theme.palette.secondary.main}, 0px 0px 5px ${theme.palette.secondary.main}`
                  : '',
              }}
              textTransform="none"
            >
              {formatNumber(value)}
            </Typography>
          )}
          <Typography variant="subtitle" sx={{ padding: '15px 0px 0px 5px' }}>
            {value !== undefined && value !== null ? displayUnits : ''}
          </Typography>
        </Stack>
        {direction != null &&
          typeof direction === 'number' &&
          arrowDirection != null &&
          typeof arrowDirection === 'number' && (
            <ArrowUpward
              color="secondary"
              sx={{
                transform: `rotate(${arrowDirection}deg)`,
                width: '40px',
                height: '40px',
              }}
            />
          )}
      </Stack>
    </Box>
  );
}
