import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import {
  useMediaQuery,
  useTheme,
  Stack,
  Box,
  Dialog,
  Button,
  IconButton,
  DialogContent,
  Typography,
  Skeleton,
} from '@mui/material';

import { Close as CloseIcon, PinDrop, Restore, ErrorOutline } from '@mui/icons-material';
import VariableInsights from './VariableInsights.jsx';
import DataGrid from './DataGrid.jsx';
import Chart from '../Charts/charts.jsx';

import * as d3 from 'd3';
import StyledIcon from '../StyledIcon.jsx';
import Crosshair from '../Crosshair/Crosshair.jsx';

import {
  server,
  getVariableTimeseriesData,
  hexToRGB,
  isSameDaySameHour,
  formatAMPM,
  formatAMPMwithMinutes,
  useWindowSize,
  makeMinutesZero,
  getPacificTime,
} from '../../utilities.js';

import stationTypes from '../../stations.js';

export default function DataPanel({
  dataPanelOpen,
  setDataPanelOpen,
  pointLocations,
  selectedStation,
  setSelectedStation,
  settings,
  sliderCurrentTime,
  selectedVariableName,
  getTideStationByID,
  landOrWater,
  setSelectedVariableName,
  setScrollTime,
  scrollTime,
  startDate,
}) {
  const size = useWindowSize();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const divHeight = document.getElementsByClassName('MuiDialogContent-root')[0];

  const [loading, setLoading] = useState(true);
  const [heightToUse, setHeightToUse] = useState();

  const [data, setData] = useState([]);
  const [variableInsights, setVariableInsights] = useState();
  const [gridData, setGridData] = useState({});
  const [variableData, setVariableData] = useState([]);
  const [variablesInPanel, setVariablesInPanel] = useState();

  const [resetTime, setResetTime] = useState();

  const formatHour = d3.timeFormat('%-I %p');
  const formatHourMin = d3.timeFormat('%-I:%M %p');

  const [tideStationMetadata, setTideStationMetadata] = useState({});

  function loadData() {
    const stationID = selectedStation?.id;
    const stationType = selectedStation?.type;
    setLoading(true);

    if (stationID)
      axios
        .get(server + `/stations/${stationType}/${stationID}/history`)
        .then((response) => {
          // get all unique keys from response.data
          const { data } = response;
          const keys = [
            ...new Set(data.data.map((elem) => Object.keys(elem.data)).flat(1)),
          ];
          const availableVariable = keys.includes(selectedVariableName)
            ? selectedVariableName
            : keys[0];

          setData(data.data.filter((d) => new Date(d.date) >= startDate));
          setVariableInsights(data.variableInsights);
          setVariableData(
            getVariableTimeseriesData(
              data.data.filter((d) => new Date(d.date) >= startDate),
              availableVariable,
              stationID
            )
          );
          setSelectedVariableName(availableVariable);

          const tideStation = JSON.parse(selectedStation.extra_station_data);

          setTideStationMetadata({
            id: selectedStation.id,
            title: selectedStation.title,
            min_tide: tideStation.min_height,
            max_tide: tideStation.max_height,
          });

          setLoading(false);
        });
    else
      axios
        .get(
          server +
            '/datapin/timeseries?' +
            new URLSearchParams({
              lat: pointLocations[0],
              lon: pointLocations[1],
              base: landOrWater,
            }).toString()
        )
        .then((response) => {
          const { data } = response;

          setData(data.data.filter((d) => new Date(d.date) >= startDate));
          setVariableInsights(data.variableInsights);
          setVariableData(
            getVariableTimeseriesData(
              data.data.filter((d) => new Date(d.date) >= startDate),
              selectedVariableName,
              stationID
            )
          );

          if (data.tideStation !== undefined) {
            setTideStationMetadata({
              id: data.tideStation.id,
              title: data.tideStation.title,
              min_tide: data.tideStation.min_height,
              max_tide: data.tideStation.max_height,
            });
          }

          setLoading(false);
        });
  }

  // This useEffect is bad, because this useEffect shares the 'selectedVariableName' with another useEffect.
  // Removing this useEffect, or combining it with the other useEffect that listens to the same
  // variable causes flickering in the charts. Not dealing with this now, but this needs to change.
  useEffect(() => {
    if (data.length) {
      setResetTime(undefined);
      setVariableData(getVariableTimeseriesData(data, selectedVariableName, stationID));
    }
  }, [selectedVariableName]);

  // Load data
  useEffect(() => {
    if (dataPanelOpen && selectedVariableName) {
      loadData();
    }
  }, [dataPanelOpen, pointLocations]);
  let tideStation;
  useEffect(() => {
    if (data.length) {
      let scrollHour = new Date(scrollTime);
      if (!data.find((d) => +new Date(d.date) === +scrollTime)?.isLive) {
        scrollHour = makeMinutesZero(scrollHour);
      }
      const c = data.find((e) => {
        let tempDate = new Date(e.date);
        tempDate = +scrollHour === +tempDate ? tempDate : makeMinutesZero(tempDate);
        return tempDate.getTime() === scrollHour.getTime();
      });
      setGridData(c?.data || {});

      if (selectedStation) {
        tideStation = getTideStationByID(selectedStation?.id);
      }
    }
  }, [scrollTime, selectedVariableName]);

  useEffect(() => {
    if (data) {
      const keys = data.reduce((acc, cur) => {
        return [
          ...acc,
          ...Object.keys(cur.data).filter((k) => cur.data[k]?.value !== null),
        ];
      }, []);
      if (keys) {
        setVariablesInPanel([...new Set(keys)]);
      }
    }
  }, [data]);

  // remove duplicates
  function closeDataPanel() {
    setDataPanelOpen(false);
    setData();
    setSelectedStation();
  }

  if (
    !selectedStation &&
    landOrWater === 'land' &&
    (selectedVariableName === 'wave' ||
      selectedVariableName === 'seaSurfaceHeight' ||
      selectedVariableName === 'current' ||
      selectedVariableName === 'potentialTemperature')
  ) {
    closeDataPanel();
  }

  const selectedStationType = stationTypes.find(
    (stationType) => stationType?.type === selectedStation?.type
  );
  // set the chart height
  useEffect(() => {
    if (divHeight?.clientHeight) {
      setHeightToUse(divHeight?.clientHeight);
    }
  }, [gridData]);

  if (divHeight?.clientHeight !== undefined && divHeight?.clientHeight !== heightToUse) {
    setHeightToUse(divHeight?.clientHeight);
  }
  const stationID =
    selectedStation?.id &&
    variableData &&
    variableData?.length &&
    selectedStationType?.type !== 'tide_stations' &&
    selectedStationType?.type !== 'currents_stations';

  return (
    <Dialog
      fullScreen={mobileView}
      open={dataPanelOpen}
      sx={{
        pointerEvents: 'auto',
        width: 'fitContent',
      }}
      onClose={(e, reason) => {
        return reason === 'backdropClick' && closeDataPanel();
      }}
      PaperProps={{
        style: { backgroundColor: theme.palette.primary.dark, minWidth: '300px' },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.primary.dark }}
        padding={1}
      >
        {selectedStation?.id ? (
          <Box
            sx={{
              height: '35px',
              width: '35px',
              borderRadius: '30px',
              border: `2px solid ${theme.palette.secondary.main}`,
              margin: '3px',
              paddingLeft: '2px',
            }}
          >
            <StyledIcon filename={selectedStationType.icon} height={20} width={27} />
          </Box>
        ) : settings.useCrosshair ? (
          <Box
            sx={{
              marginLeft: '5px',
            }}
          >
            <Crosshair
              crosshairWidth={30}
              ringColor={theme.palette.secondary.main}
              pipColor={theme.palette.secondary.main}
            />
          </Box>
        ) : (
          <PinDrop color="secondary" />
        )}
        <Stack alignItems="center">
          <Typography color="secondary">
            {tideStationMetadata.id && selectedVariableName === 'seaSurfaceHeight'
              ? 'Tide station: ' + tideStationMetadata.title
              : selectedStation?.title || selectedStation?.id || 'Ocean Conditions'}
          </Typography>
          {stationID ? (
            <Typography variant="caption" color="secondary" sx={{ fontStyle: 'italic' }}>
              {variableData.find((d) => d.isLive === true)
                ? `${selectedStationType.typeName}, last updated ${formatAMPMwithMinutes(
                    variableData[variableData?.length - 1]?.date
                  )} `
                : `${selectedStationType.typeName}, last updated ${formatAMPM(
                    variableData[variableData?.length - 1]?.date
                  )} `}
            </Typography>
          ) : null}
        </Stack>
        <IconButton onClick={closeDataPanel} aria-label="close">
          <CloseIcon color="secondary" />
        </IconButton>
      </Stack>
      {variablesInPanel?.length === 0 && Object.keys(gridData).length === 0 ? (
        <Box
          display="flex"
          sx={{
            backgroundColor: theme.palette.primary.dark,
            width: '100%',
            height: selectedStation?.id && !mobileView ? 150 : 75,
          }}
        >
          <Box sx={{ m: 'auto', width: '100%' }}>
            <Skeleton
              animation="wave"
              sx={{
                height: selectedStation?.id && !mobileView ? 140 : 70,
                width: '100%',
              }}
              variant="rectangular"
            />
          </Box>
        </Box>
      ) : (
        !loading &&
        variablesInPanel?.length > 1 &&
        Object.keys(gridData).length >= 0 && (
          <DataGrid
            setSelectedVariableName={setSelectedVariableName}
            data={gridData}
            variableNameSelected={selectedVariableName}
            variablesInPanel={variablesInPanel}
            settings={settings}
          />
        )
      )}
      <Stack
        sx={{ marginTop: '10px', overflowY: 'hidden', height: '100%' }}
        alignItems="center"
      >
        {loading && Object.keys(gridData).length === 0 ? (
          <Box
            display="flex"
            sx={{
              backgroundColor: theme.palette.primary.dark,
              width: '100%',
              height: 140,
            }}
          >
            <Box sx={{ m: 'auto', width: '100%' }}>
              <Skeleton
                animation="wave"
                sx={{ height: 120, width: '100%' }}
                variant="rounded"
              />
            </Box>
          </Box>
        ) : (
          !loading &&
          heightToUse !== undefined && (
            <VariableInsights
              selectedVariableName={selectedVariableName}
              scrollTime={scrollTime}
              varData={gridData[selectedVariableName]}
              variableInsights={variableInsights}
              tideStationMetadata={tideStationMetadata}
              variableData={variableData}
              liveData={variableData.find((d) => d.isLive === true)}
              formatHourMin={formatHourMin}
              settings={settings}
              selectedStation={selectedStation}
            />
          )
        )}
        {loading ? (
          <Box
            display="flex"
            sx={{
              backgroundColor: theme.palette.primary.dark,
              width: '100%',
              height: mobileView ? 400 : 435,
            }}
          >
            <Box sx={{ m: 'auto', width: '100%' }}>
              <Skeleton
                animation="wave"
                sx={{ height: mobileView ? 400 : 435, width: '100%' }}
                variant="rectangular"
              />
            </Box>
          </Box>
        ) : (
          <DialogContent sx={{ padding: '0px', height: '100%' }}>
            {sliderCurrentTime !== undefined && !variableData.length ? (
              <Box display="flex" width={600} height={400} bgcolor="#023555">
                <Box m="auto" color="white" flexDirection="column">
                  <ErrorOutline />
                  <span> Data not available</span>
                </Box>
              </Box>
            ) : (
              !loading &&
              heightToUse !== undefined && (
                <>
                  <Chart
                    variable={selectedVariableName}
                    data={variableData}
                    insightData={
                      variableInsights ? variableInsights[selectedVariableName] : []
                    }
                    setScrollTime={setScrollTime}
                    resetTime={resetTime}
                    scrollTime={scrollTime}
                    sliderCurrentTime={sliderCurrentTime}
                    pointLocations={pointLocations}
                    size={size}
                    isStation={Boolean(selectedStation?.type)}
                    settings={settings}
                  />

                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      backgroundColor: `rgba(${hexToRGB(
                        theme.palette.secondary.main
                      )}, 0.8)`,
                      color: theme.palette.primary.main,
                      position: 'absolute',
                      bottom: 5,
                      left: mobileView ? size.width / 2 - 50 : 600 / 2 - 50,
                      width: '100px',
                      height: '25px',
                      borderRadius: '20px',
                      pointerEvents: 'auto',
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      window.clarity('event', "Datapanel 'go to now' click");
                      setResetTime(new Date(getPacificTime()));
                    }}
                    endIcon={
                      (variableData.find((d) => d?.isLive === true) &&
                        selectedStation &&
                        (selectedVariableName !== 'seaSurfaceHeight' ||
                          selectedVariableName !== 'current') &&
                        !(
                          +scrollTime === +variableData[variableData.length - 1]?.date
                        ) && <Restore color="primary" />) ||
                      ((!selectedStation ||
                        (selectedStation &&
                          !variableData.find((d) => d?.isLive === true))) &&
                        !isSameDaySameHour(
                          scrollTime,
                          new Date(getPacificTime()).getTime()
                        ) && <Restore color="primary" />)
                    }
                  >
                    {variableData.find((d) => d.isLive === true) &&
                    +scrollTime === +variableData[variableData.length - 1]?.date
                      ? formatHourMin(scrollTime)
                      : formatHour(scrollTime)}
                  </Button>
                </>
              )
            )}
          </DialogContent>
        )}
      </Stack>
    </Dialog>
  );
}
