import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import './styles.css';
import { axisBottom } from './d3-axis@1.js';
import DirectionArrow from '../../Images/direction_arrow.svg';
import { useTheme, useMediaQuery } from '@mui/material/';

import mapLayers from '../../mapLayers.js';

import {
  roundNumber,
  getUnitMultiplier,
  makeMinutesZero,
  getPacificTime,
  getArrowDirection,
} from '../../utilities';
import clonedeep from 'lodash.clonedeep';
import {
  lineGenerator,
  areaGenerator,
  getSubVariableLabels,
  multiFormat,
  getSunData,
  normalizeDatasetTime,
  pulse,
  makeDimensions,
  getSubVariables,
  makeClipPath,
  getZoomLevel,
} from './chartsUtilities.jsx';

function Chart({
  variable,
  data: rawData,
  insightData: rawInsightData,
  setScrollTime,
  resetTime,
  scrollTime,
  sliderCurrentTime,
  pointLocations,
  size,
  isStation,
  settings,
}) {
  const { unit } = mapLayers.find((e) => e.variableName === variable);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const pathRef = useRef();
  const [dataConverted, setDataConverted] = useState(rawData);
  const [insightData, setInsightsConverted] = useState(rawInsightData);
  const [unitsConverted, setUnitsConverted] = useState(unit);
  let data = dataConverted;
  const axisRef = useRef();
  const firstLoad = useRef(true);
  const linePosition = useRef();
  let sunData;
  let xScaleCurrent;
  const svgRef = useRef(null);
  const isCurrentsOrTidesStation =
    variable === 'seaSurfaceHeight' || variable === 'current';
  const zoomLevel = getZoomLevel(isCurrentsOrTidesStation, mobileView, isStation);
  const chartWidth = mobileView ? size.width : 600; //* (2 / 3) - size.width * 0.01;
  const chartHeight = useRef(300);
  const scale = 0.018;

  const subVariables = getSubVariables(data, variable);

  const subVariableLabels = getSubVariableLabels(settings);

  const subVariableLength = subVariables[variable].length;
  const variableUnits = unitsConverted;
  useEffect(() => {
    const { displayUnits, multiplier } = getUnitMultiplier(unit, settings.units);
    setUnitsConverted(displayUnits);
    let unitsData = clonedeep(rawData);
    let insightUnitsData = clonedeep(rawInsightData);

    if (unitsData) {
      unitsData = unitsData.map((d) => {
        d.value = multiplier(d.value);
        variable === 'wind' && d.gust !== null && (d.gust = multiplier(d.gust));
        return d;
      });
      setDataConverted(unitsData);
    }
    if (insightUnitsData) {
      insightUnitsData = insightUnitsData.map((d) => {
        d.value = multiplier(d?.value);
        return d;
      });
      setInsightsConverted(insightUnitsData);
    }
  }, [rawData, resetTime, settings.units, rawInsightData]);
  useEffect(() => {
    const heightToUse = document.getElementsByClassName('MuiDialogContent-root')[0]
      .clientHeight;
    chartHeight.current =
      mobileView && heightToUse !== undefined
        ? heightToUse - 16
        : size.height > 800
        ? 400
        : size.height > 700 && size.height < 799
        ? 300
        : 240;

    const dimensions = makeDimensions(
      mobileView,
      chartWidth,
      chartHeight.current,
      subVariableLength
    );

    const zoom = d3
      .zoom()
      .scaleExtent([zoomLevel, zoomLevel])
      .translateExtent([
        [
          (dimensions.margin.left -
            (dimensions.width + dimensions.margin.left - dimensions.margin.right) / 2 +
            1) /
            zoomLevel,
          -Infinity,
        ],
        [
          dimensions.width -
            dimensions.margin.right +
            (dimensions.width + dimensions.margin.right - dimensions.margin.left) /
              2 /
              zoomLevel,
          Infinity,
        ],
      ])
      .on('zoom', (e) => zoomed(e, variableUnits));
    if (isStation && data.length > 1) {
      data = normalizeDatasetTime(data);
    }
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => new Date(d.date)))
      .range([dimensions.margin.left, dimensions.width - dimensions.margin.right]);

    const yAccessor = (d) => d.value;
    const xAccessor = (d) => new Date(d.date);
    const daterange = data.map((d) => new Date(d.date));
    const yDomain = [
      variable === 'airTemperature' || variable === 'potentialTemperature'
        ? d3.min(data, yAccessor) - 2
        : 0,
      (variable === 'wave' || variable === 'current' || variable === 'wind') &&
      d3.max(data, yAccessor) < 1
        ? 1
        : variable === 'wave'
        ? d3.max(data, yAccessor) + 1
        : d3.max(data, yAccessor) + 2,
    ];
    const yScale = d3
      .scaleLinear()
      .domain(yDomain)
      .range([
        variable === 'airTemperature' ||
        variable === 'potentialTemperature' ||
        variable === 'seaSurfaceHeight'
          ? dimensions.height - dimensions.margin.bottom - dimensions.margin.bottomOfPlot
          : dimensions.height - dimensions.margin.bottom - dimensions.margin.bottomOfPlot,
        dimensions.margin.top,
      ]);
    const yAxisGenerator = d3.axisLeft().ticks(6).scale(yScale);

    const xAxis = (g, x) =>
      g
        .attr(
          'transform',
          `translate(0,${dimensions.height - dimensions.margin.bottomOfPlot})`
        )
        .call(
          axisBottom(x)
            .ticks(d3.timeHour)
            .tickSizeOuter(0)
            .tickFormat((d, i) => {
              if (
                d >= d3.min(data, (d) => new Date(d.date)) &&
                d <= d3.max(data, (d) => new Date(d.date))
              ) {
                return !mobileView
                  ? d3.timeDay(d) < d
                    ? null
                    : multiFormat(d)
                  : d.getHours() % 12 === 0
                  ? multiFormat(d)
                  : null;
              }
            })
            .tickSize((d, i) =>
              d >= d3.min(data, (d) => new Date(d.date)) &&
              d <= d3.max(data, (d) => new Date(d.date))
                ? d.getHours() % 4 === 0
                  ? d3.timeDay(d) < d
                    ? 8
                    : 20
                  : null
                : null
            )
        )
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('font-weight', (d) => (d3.timeDay(d) < d ? 400 : 700))
            .attr('x', (d) => (d3.timeDay(d) < d ? 0 : 8))
            .attr('text-anchor', (d) => (d3.timeDay(d) < d ? 'middle' : 'start'))
            .attr('y', 10)
        )
        .call((g) => g.select('.domain').remove());

    function zoomed(event, variableUnits) {
      xScaleCurrent = event.transform.rescaleX(xScale);

      setScrollTime(xScaleCurrent.invert(linePosition.current));
      if (variable === 'airTemperature' || variable === 'potentialTemperature') {
        pathRef.current.attr(
          'd',
          lineGenerator(data, xAccessor, yAccessor, xScaleCurrent, yScale)
        );
      } else {
        pathRef.current.attr(
          'd',
          areaGenerator(
            data,
            xAccessor,
            yAccessor,
            xScaleCurrent,
            yScale,
            variable,
            isCurrentsOrTidesStation,
            dimensions
          )
        );
        d3.selectAll('.arrowHead1').attr('transform', function (d, i) {
          // width * 0.015 is width/2 * scale, needed to rotate around image centroid

          d.correctedD = d.direction - 90;

          // wind and wave are in "from direction"
          const arrowDirection = getArrowDirection(variable, d.correctedD);

          return (
            'translate(' +
            (xScaleCurrent(xAccessor(d)) - 400 * scale) +
            ',' +
            (dimensions.height - dimensions.margin.yArrowPos) +
            ') rotate(' +
            arrowDirection +
            ',' +
            400 * scale +
            ',' +
            400 * scale +
            ') scale(' +
            scale +
            ')'
          );
        });
      }

      for (let i = 0; i < subVariables[variable].length; i++) {
        const yTextPos = dimensions.height - dimensions.margin.yTextPos + (35 / 2) * i;
        d3.selectAll('.text' + subVariables[variable][i]).attr('transform', function (d) {
          return 'translate(' + xScaleCurrent(xAccessor(d)) + ',' + yTextPos + ')';
        });
      }
      gtide.selectAll('text').attr('transform', function (d) {
        return (
          'translate(' + xScaleCurrent(xAccessor(d)) + ',' + (yScale(d.value) - 5) + ')'
        );
      });

      gtideLines
        .selectAll('line')
        .attr('x1', (d, i) => {
          return xScaleCurrent(new Date(d.date));
        })
        .attr('x2', (d, i) => xScaleCurrent(new Date(d.date)))
        .attr('y2', (d) => yScale(d.value))
        .attr('y1', (d) => yScale(0));

      gbars
        .selectAll('rect')
        .attr('x', (d) => xScaleCurrent(new Date(d.sunrise)))
        .attr(
          'width',
          (d) => xScaleCurrent(new Date(d.sunset)) - xScaleCurrent(new Date(d.sunrise))
        );

      gbarsDawn
        .selectAll('rect')
        .attr('x', (d) => xScaleCurrent(new Date(d.dawn)))
        .attr(
          'width',
          (d) => xScaleCurrent(new Date(d.sunrise)) - xScaleCurrent(new Date(d.dawn)) + 2
        );

      gbarsDusk
        .selectAll('rect')
        .attr('x', (d) => xScaleCurrent(new Date(d.sunset)) - 2)
        .attr('width', (d) => {
          return xScaleCurrent(new Date(d.dusk)) - xScaleCurrent(new Date(d.sunset)) + 2;
        });

      axisRef.current.call(xAxis, xScaleCurrent);
      if (isStation && variable !== 'seaSurfaceHeight' && variable !== 'current') {
        gdots
          .selectAll('circle')
          .attr('cx', (d) => xScaleCurrent(d.date))
          .attr('cy', (d) => yScale(d.value));
      }
    }

    const clip = makeClipPath();

    sunData = getSunData({ data, pointLocations });

    const svg = d3
      .select(svgRef.current)
      .attr('width', dimensions.width)
      .attr('height', dimensions.height)
      .attr('class', 'svgViz');

    const everything = svg.selectAll('*');
    everything.remove();

    svg
      .append('rect')
      .attr('width', mobileView ? '100%' : 600)
      .attr('height', '100%')
      .attr('fill', theme.palette.primary.dark);

    const nightBackground = svg
      .append('g')
      .append('rect')
      .attr('x', 0)
      .attr('y', yScale(yDomain[1]))
      .attr('width', dimensions.width)
      .attr('height', (d) => yScale(yDomain[0]) - yScale(yDomain[1]))
      .attr('fill', '#333');

    const gbars = svg.append('g');
    const gbarsDawn = svg.append('g');
    const gbarsDusk = svg.append('g');

    gbars
      .append('g')
      .selectAll('rect')
      .data(sunData)
      .join('rect')
      .attr('x', (d) => {
        return xScale(new Date(d.sunrise));
      })
      .attr('width', (d) => {
        return xScale(new Date(d.sunset)) - xScale(new Date(d.sunrise));
      })
      .attr('y', yScale(yDomain[1]))
      .attr('height', (d) => yScale(yDomain[0]) - yScale(yDomain[1]))
      .attr('fill', '#5cb6ff')
      .attr('fill-opacity', '1');

    gbarsDawn
      .append('g')
      .selectAll('rect')
      .data(sunData)
      .join('rect')
      .attr('x', (d) => {
        return xScale(new Date(d.dawn));
      })
      .attr('width', (d) => {
        return xScale(new Date(d.sunrise)) - xScale(new Date(d.dawn));
      })
      .attr('y', yScale(yDomain[1]))
      .attr('height', (d) => yScale(yDomain[0]) - yScale(yDomain[1]))
      .attr('fill', '#7ec3fb')
      .attr('fill-opacity', '1');

    gbarsDusk
      .append('g')
      .selectAll('rect')
      .data(sunData)
      .join('rect')
      .attr('x', (d) => {
        return xScale(new Date(d.dusk));
      })
      .attr('width', (d) => {
        return xScale(new Date(d.dusk)) - xScale(new Date(d.sunset));
      })
      .attr('y', yScale(yDomain[1]))
      .attr('height', (d) => yScale(yDomain[0]) - yScale(yDomain[1]))
      .attr('fill', '#7ec3fb')
      .attr('fill-opacity', '1');

    pathRef.current =
      variable === 'airTemperature' || variable === 'potentialTemperature'
        ? svg
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', '#fafafa')
            .attr('stroke-width', 4)
            .attr('d', lineGenerator(data, xAccessor, yAccessor, xScale, yScale))
        : svg
            .append('path')
            .attr('fill', 'steelblue')
            .attr(
              'd',
              areaGenerator(
                data,
                xAccessor,
                yAccessor,
                xScale,
                yScale,
                variable,
                isCurrentsOrTidesStation,
                dimensions
              )
            );

    const gdots = svg.append('g');
    if (isStation && variable !== 'seaSurfaceHeight' && variable !== 'current')
      gdots
        .append('g')
        .selectAll('circle')
        .data(data.filter((d) => !isNaN(d.value) && d.value != null))
        .join('circle')
        .attr('cx', (d) => xScale(d.date))
        .attr('cy', (d) => yScale(d.value))
        .attr('class', (d) => (d.isLive === true ? 'isLive' : 'hourly'))
        .attr('fill', (d) => {
          if (d.isLive === undefined) {
            return variable === 'current' ||
              variable === 'wind' ||
              variable === 'wave' ||
              variable === 'seaSurfaceHeight'
              ? 'steelblue'
              : '#fafafa';
          } else {
            return 'orange';
          }
        })
        .attr('stroke', (d) => (d.isLive === true ? 'orange' : '#023555'))
        .attr('r', 4);

    const circle = d3.select('.isLive');

    pulse(circle);

    const gArrows = svg.append('g');

    const gGridValues = svg.append('g');

    svg
      .append('g')
      .attr('clip-path', clip)
      .append('rect')
      .attr('x', 0)
      .attr(
        'y',
        dimensions.height - dimensions.margin.bottom - dimensions.margin.bottomOfPlot
      )
      .attr('width', variable === 'wind' ? 70 : variable === 'current' ? 80 : 60)
      .attr('height', 200)
      .attr('fill', '#023555');

    const gtide = svg.append('g');

    const gtideLines = svg.append('g');

    const gridData = data.filter((item) => {
      const date = new Date(item.date);
      const minutes = date.getMinutes();
      return minutes === 0 || minutes > 30;
    });

    if (
      variable === 'current' ||
      variable === 'wind' ||
      (variable === 'wave' &&
        data.filter((d) => !isNaN(d.direction) && d.direction != null).length > 0)
    ) {
      const node = gArrows
        .append('g')
        .selectAll('g')
        .data(gridData.filter((d) => !isNaN(d.direction) && d.direction != null))
        .join('g');

      node.append('image').attr('class', 'arrowHead1').attr('xlink:href', DirectionArrow);
    }

    // Y grid labels
    if (subVariables[variable].length > 1) {
      for (let i = 0; i < subVariables[variable].length; i++) {
        svg
          .append('g')
          .append('text')
          .attr('text-anchor', 'end')
          .attr('font-size', 10)
          .style('fill', '#e0f5ee')
          .attr(
            'x',
            variable === 'wind' && mobileView
              ? 65
              : variable === 'wind'
              ? 60
              : variable === 'current'
              ? 70
              : 50
          )
          .attr(
            'y',
            dimensions.height -
              dimensions.margin.bottom -
              dimensions.margin.bottomOfPlot +
              17 * (i + 1)
          )
          .text(subVariableLabels[variable][subVariables[variable][i]]);

        // Y grid values

        if (
          subVariables[variable][i] !== 'direction' &&
          subVariables[variable][i] !== 'isLive'
        ) {
          gGridValues
            .append('g')
            .selectAll('text')
            .data(
              gridData.filter(
                (d) => !isNaN(d[subVariables[variable][i]]) && d.value != null
              )
            )
            .join('text')
            .text((d) => {
              return d[subVariables[variable][i]]?.toFixed(0);
            })
            .attr('class', 'text' + subVariables[variable][i])
            .attr('font-family', 'sans-serif')
            .attr('font-size', '12px')
            .attr('text-anchor', 'middle')
            .attr('fill', '#e0f5ee');
        }
      }
    }
    if (variable === 'seaSurfaceHeight') {
      gtide
        .append('g')
        .selectAll('text')
        .data(
          insightData.filter(
            (d) =>
              new Date(d.date) >= d3.min(data, (j) => new Date(j.date)) &&
              new Date(d.date) <= d3.max(data, (j) => new Date(j.date))
          )
        )
        .join('text')
        .text((d) => {
          return roundNumber(d.value).toString() + variableUnits;
        })
        .attr('class', 'peakTides')
        .attr('font-family', 'sans-serif')
        .attr('font-size', '12px')
        .attr('text-anchor', 'middle')
        .attr('fill', '#e0f5ee');

      gtideLines
        .append('g')
        .selectAll('line')
        .data(
          insightData.filter(
            (d) =>
              new Date(d.date) >= d3.min(data, (j) => new Date(j.date)) &&
              new Date(d.date) <= d3.max(data, (j) => new Date(j.date))
          )
        )
        .join('line')
        .attr('x1', (d, i) => {
          return xScale(new Date(d.date));
        })
        .attr('x2', (d, i) => xScale(new Date(d.date)))
        .attr('y1', yScale(0))
        .attr('y2', yScale(3))
        .attr('fill', 'none')
        .attr('stroke', '#e0f5ee')
        .attr('stoke-width', 1)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-opacity', 0.6);
    }

    // YAXIS
    svg
      .append('g')
      .call(yAxisGenerator)
      .attr('font-size', '12px')
      .call((g) => g.select('.domain').remove())
      .style('transform', `translateX(${dimensions.margin.left}px)`)
      .attr('class', 'axisWhite')
      .call((g) =>
        g
          .select('.tick:last-of-type text')
          .clone()
          .attr(
            'x',
            variable === 'current' || variable === 'wind'
              ? -60
              : variableUnits === 'ft' &&
                (variable === 'seaSurfaceHeight' || variable === 'wave')
              ? -40
              : -50
          )
          .attr('text-anchor', 'start')
          .text('\u00A0' + variableUnits)
      )
      .selectAll('text')
      .attr('x', 24);

    const gx = svg.append('g');

    gx.call(xAxis, xScale).attr('class', 'axisWhite');

    axisRef.current = gx;

    const gRule = svg.append('g');

    const rule = gRule
      .append('line')
      .attr('class', 'rule')
      .attr('y1', dimensions.margin.top)
      .attr(
        'y2',
        variable === 'airTemperature' ||
          variable === 'potentialTemperature' ||
          variable === 'seaSurfaceHeight'
          ? dimensions.height - dimensions.margin.bottom - dimensions.margin.bottomOfPlot
          : dimensions.height - dimensions.margin.bottom - dimensions.margin.bottomOfPlot
      )
      .attr('stroke', theme.palette.secondary.main)
      .attr('stroke-opacity', 0.7)
      .attr('stroke-width', 2);

    // update rule on first load to make sure line remains in place
    // tide stations act more like model data because they are
    let hourTime = clonedeep(scrollTime);

    if (!data.find((d) => +d.date === +scrollTime)?.isLive && hourTime) {
      hourTime = makeMinutesZero(hourTime);
    }

    let date;

    if (
      firstLoad.current === true && // first open, past data only
      new Date(sliderCurrentTime) > daterange[daterange.length - 1]
    ) {
      date = daterange[daterange.length - 1];
    } else if (
      // first opening

      firstLoad.current === true &&
      new Date(sliderCurrentTime) < daterange[daterange.length - 1]
    ) {
      date = new Date(
        daterange[
          d3.bisectLeft(
            daterange,
            new Date(sliderCurrentTime).setHours(
              new Date(sliderCurrentTime).getHours() - 1
            )
          )
        ]
      );
    } else if (firstLoad.current === false && !resetTime) {
      // use chart slider time
      date = new Date(hourTime);
    } else if (resetTime && new Date(resetTime) > daterange[daterange.length - 1]) {
      date = new Date(
        (date = new Date(
          daterange[d3.bisectLeft(daterange, daterange[daterange.length - 1])]
        ))
      );
    } else if (resetTime) {
      date = new Date(
        daterange[
          d3.bisectLeft(
            daterange,
            new Date(getPacificTime()).setHours(new Date(getPacificTime()).getHours() - 1)
          )
        ]
      );
    }
    const scrubberData = data.find((d) => +new Date(d.date) === +new Date(date));
    xScaleCurrent = xScale;

    svg.call(zoom).call(
      zoom.transform,
      d3.zoomIdentity
        .translate(
          (dimensions.width + dimensions.margin.left - dimensions.margin.right) / 2,
          dimensions.height / 2
        )
        .scale(zoomLevel)
        .translate(-xScale(scrubberData?.date), 0)
    );
    rule.attr('transform', `translate(${xScaleCurrent(scrubberData?.date) + 1},0)`);

    const linePos = xScaleCurrent(scrubberData.date);
    linePosition.current = linePos;

    setScrollTime(xScaleCurrent.invert(linePos));
  }, [data, mobileView, resetTime, size.width, settings.units]);

  useEffect(() => {
    if (data.length) {
      firstLoad.current = false;
    }
  }, [variable]);

  return <svg ref={svgRef}></svg>;
}

export default Chart;
