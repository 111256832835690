import React from 'react';

import {
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  Collapse,
  Stack,
  IconButton,
  Typography,
  Fab,
} from '@mui/material';
import mapLayers from '../../mapLayers.js';

import {
  Close as CloseIcon,
  ModeOfTravelRounded,
  ShowChartRounded,
} from '@mui/icons-material';

import {
  useWindowSize,
  getUnitMultiplier,
  formatNumberGenerator,
  getArrowDirection,
} from '../../utilities';

import MiniDrawer from './MiniDrawer.jsx';
import ExpandedDrawer from './ExpandedDrawer.jsx';
import { scaleLinear } from 'd3-scale';

export default function DataDrawer({
  pointLocations,
  selectedVariableName,
  setDataPinDrawerOpen,
  setDataPanelOpen,
  moveSelectedVariable,
  setflyToCoords,
  drawerTitle = '',
  setSelectedVariableName,
  drawerData,
  settings,
  mapCenterPoint,
  drawerCollapsed,
  setDrawerCollapsed,
  loadingDrawerData,
  landOrWater,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const windowSize = useWindowSize();
  const smallScreen = windowSize.height < 700 && windowSize.width < 700;
  const barHeight = smallScreen ? 40 : 45;

  const selectedVariable = mapLayers.find((e) => e.variableName === selectedVariableName);

  const {
    value,
    direction,
    change,
    high,
    low,
    period,
    gust,
    title: tideStationTitle,
  } = drawerData[selectedVariableName] || {};

  const { displayUnits, multiplier } = getUnitMultiplier(
    selectedVariable.unit,
    settings.units
  );

  const formatNumber = formatNumberGenerator(multiplier);
  const hasData = value !== undefined && value !== null;
  let tideData;

  const arrowDirection = getArrowDirection(selectedVariableName, direction);

  const selectedMapVar = mapLayers.find((e) => e.variableName === selectedVariableName);
  const { scale } = selectedMapVar;
  const dataGradient = scaleLinear()
    .domain(scale.map((step) => step.value))
    .range(scale.map((step) => step.color))
    .clamp(true)
    .unknown(theme.palette.secondary.main);

  const returnToDataPinDisabled =
    !settings.useCrosshair &&
    Array.isArray(mapCenterPoint) &&
    mapCenterPoint[0].toFixed(10) === pointLocations[1].toFixed(10) &&
    mapCenterPoint[1].toFixed(10) === pointLocations[0].toFixed(10);

  return (
    <Box
      position="fixed"
      sx={{
        pointerEvents: 'none',
        bottom: '63px',
        zIndex: 1000,
        padding: window.innerWidth > 400 ? '0px 10px 0px 10px' : '0px 0px 0px 0px',
        width: mobileView ? 'calc(100% - 20px)' : '400px',
        maxWidth: '400px',
        left: '50%',
        transform: 'translate(-50%, 0px)',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: '-10px',
          left: `calc(50% - 9px)`,
          pointerEvents: 'none',
          zIndex: 100,
          width: 0,
          height: 0,
          borderLeft: `10px solid transparent`,
          borderRight: `10px solid transparent`,
          borderTop: `10px solid ${theme.palette.secondary.main}`,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '-6px',
          left: `calc(50% - 7px)`,
          pointerEvents: 'none',
          zIndex: 100,
          width: 0,
          height: 0,
          borderLeft: `8px solid transparent`,
          borderRight: `8px solid transparent`,
          borderTop: `8px solid ${theme.palette.primary.dark}`,
        }}
      />
      <Paper
        elevation={5}
        sx={{
          backgroundColor: `${theme.palette.primary.dark}`,
          color: 'white',
          borderRadius: `${barHeight / 2 + 1}px`,
          pointerEvents: 'auto',
          padding: '1px',
          overflow: 'hidden',
        }}
      >
        <Stack sx={{ textAlign: 'center' }}>
          <Stack
            direction="row"
            alignItems="top"
            justifyContent="space-between"
            sx={{
              height: `${barHeight}px`,
              paddingLeft: smallScreen ? '1px' : '3px',
              paddingRight: smallScreen ? '1px' : '3px',
              paddingTop: '3px',
            }}
          >
            <IconButton
              color="secondary"
              onClick={() => {
                window.clarity(
                  'event',
                  drawerCollapsed ? 'Expand drawer' : 'Minimize drawer'
                );
                setDrawerCollapsed(!drawerCollapsed);
              }}
              sx={{
                borderRadius: '25px',
                backgroundColor: theme.palette.primary.main,
                minWidth: '45px',
                width: 'fit-content',
                height: '35px',
              }}
            >
              <Typography variant="caption" color="secondary">
                {drawerCollapsed ? 'more' : 'less'}
              </Typography>
            </IconButton>
            <Fab
              aria-label="Charts"
              color="primary"
              disabled={!hasData}
              onClick={() => {
                window.clarity('event', 'Charts from mini drawer');
                setDataPanelOpen(true);
              }}
              sx={{
                borderRadius: '25px',
                border: `2px solid ${theme.palette.secondary.main}`,
                background: theme.palette.primary.main,
                minWidth: '45px',
                width: 'fit-content',
                height: '35px',
                padding: drawerCollapsed ? '2px 5px' : '2px 10px',
                '&:hover': {
                  background: theme.palette.primary.dark,
                  border: `2px solid ${theme.palette.secondary.light}`,
                },
                '&.Mui-disabled': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.main,
                  border: `2px solid ${theme.palette.primary.light}`,
                },
                boxShadow: 'none',
                textTransform: 'none',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '24px',
                  height: '20px',
                  borderLeft: `2px solid ${
                    hasData ? theme.palette.secondary.main : theme.palette.primary.light
                  }`,
                  borderBottom: `2px solid ${
                    hasData ? theme.palette.secondary.main : theme.palette.primary.light
                  }`,
                  borderRadius: '2px',
                }}
              >
                <Box sx={{ position: 'absolute', top: '-4px', left: '0px' }}>
                  <ShowChartRounded
                    htmlColor={!hasData ? theme.palette.primary.light : 'orange'}
                  />
                </Box>
              </Box>
              {!drawerCollapsed && (
                <Typography
                  color={hasData ? 'secondary' : theme.palette.primary.main}
                  variant="caption"
                  sx={{ marginLeft: '5px' }}
                >
                  charts
                </Typography>
              )}
            </Fab>
            {drawerCollapsed && (
              <MiniDrawer
                setDrawerCollapsed={setDrawerCollapsed}
                dataColor={dataGradient(value)}
                value={value}
                formatNumber={formatNumber}
                displayUnits={displayUnits}
                direction={direction}
                arrowDirection={arrowDirection}
                loadingDrawerData={loadingDrawerData}
                selectedVariableName={selectedVariableName}
              />
            )}
            {!settings.useCrosshair && (
              <IconButton
                color="secondary"
                onClick={() => {
                  setflyToCoords([pointLocations[1], pointLocations[0]]);
                }}
                disabled={returnToDataPinDisabled || settings.useCrosshair}
                sx={{
                  borderRadius: '25px',
                  backgroundColor: theme.palette.primary.main,
                  minWidth: '35px',
                  width: 'fit-content',
                  height: '35px',
                  '&:focus': {
                    backgroundColor: theme.palette.primary.main,
                  },
                  '&.Mui-disabled': {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <ModeOfTravelRounded sx={{ transform: 'scaleX(-1)' }} />
                {!drawerCollapsed && (
                  <Typography
                    color={
                      returnToDataPinDisabled || settings.useCrosshair
                        ? 'primary'
                        : 'secondary'
                    }
                    variant="caption"
                    sx={{ marginLeft: '2px' }}
                  >
                    return
                  </Typography>
                )}
              </IconButton>
            )}
            {settings.useCrosshair && drawerCollapsed && (
              <Box sx={{ width: '35px' }}></Box>
            )}
            <IconButton
              color="secondary"
              onClick={() => {
                window.clarity('event', 'Drawer close (X)');
                setDataPinDrawerOpen(false);
              }}
              sx={{
                borderRadius: '25px',
                background: theme.palette.primary.main,
                width: '35px',
                height: '35px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          {tideStationTitle && (
            <Typography color="secondary" sx={{ textTransform: 'none' }}>
              Tide station: {tideStationTitle}
            </Typography>
          )}
          {drawerTitle && (
            <Typography color="secondary" sx={{ textTransform: 'none' }}>
              {drawerTitle}
            </Typography>
          )}
          {!tideStationTitle && drawerCollapsed && (
            <Typography color="secondary" sx={{ textTransform: 'none' }}>
              {selectedVariable.displayName}
            </Typography>
          )}
        </Stack>
        <Collapse in={!drawerCollapsed}>
          <ExpandedDrawer
            dataColor={dataGradient(value)}
            value={value}
            formatNumber={formatNumber}
            displayUnits={displayUnits}
            direction={direction}
            arrowDirection={arrowDirection}
            moveSelectedVariable={moveSelectedVariable}
            drawerData={drawerData}
            hasData={hasData}
            setDataPanelOpen={setDataPanelOpen}
            selectedVariableName={selectedVariableName}
            period={period}
            gust={gust}
            change={change}
            high={high}
            low={low}
            setSelectedVariableName={setSelectedVariableName}
            barHeight={barHeight}
            selectedVariable={selectedVariable}
            loadingDrawerData={loadingDrawerData}
            tideData={tideData}
          />
        </Collapse>
      </Paper>
    </Box>
  );
}
