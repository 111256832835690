import * as d3 from 'd3';
import * as suncalc from 'suncalc';
import mapLayers from '../../mapLayers.js';
import { getUnitMultiplier } from '../../utilities.js';

export function getZoomLevel(isCurrentsOrTidesStation, mobileView, isStation) {
  return isStation && mobileView && isCurrentsOrTidesStation
    ? 8
    : isStation && mobileView
    ? 3
    : isStation && !mobileView && isCurrentsOrTidesStation
    ? 4
    : isStation && !mobileView
    ? 2
    : mobileView
    ? 6
    : 3;
}

export function getSubVariableLabels(settings) {
  const labels = {
    airTemperature: { value: 'Temp.' },
    potentialTemperature: { value: 'Temp.' },
    seaSurfaceHeight: { value: 'Height' },
    current: {
      value: 'Current',
      direction: 'Direction',
    },
    wind: {
      value: 'Wind',
      direction: 'Direction',
      gust: 'Gusts',
    },
    wave: {
      value: 'Height',
      direction: 'Direction',
      period: 'Period (s)',
    },
  };
  Object.keys(labels).forEach((key) => {
    const { unit } = mapLayers.find((e) => e.variableName === key);
    const { displayUnits } = getUnitMultiplier(unit, settings.units);
    labels[key].value += ' (' + displayUnits + ')';
    labels[key].gust += ' (' + displayUnits + ')';
  });

  return labels;
}

export function makeDimensions(mobileView, chartWidth, chartHeight, subVariableLength) {
  const bottomOfPlot = mobileView ? 35 : 45;
  return {
    width: chartWidth,
    height: chartHeight,
    margin: {
      top: 10,
      right: 0,
      left: 0,
      bottom:
        subVariableLength === 1
          ? mobileView
            ? bottomOfPlot - 30
            : bottomOfPlot - 40
          : subVariableLength === 2
          ? mobileView
            ? bottomOfPlot + 5
            : bottomOfPlot - 5
          : mobileView
          ? bottomOfPlot + 25
          : bottomOfPlot + 15,
      yArrowPos:
        subVariableLength === 1
          ? bottomOfPlot + 25
          : subVariableLength === 2
          ? bottomOfPlot + 18
          : bottomOfPlot + 18,
      yTextPos:
        subVariableLength === 1
          ? bottomOfPlot + 8
          : subVariableLength === 2
          ? bottomOfPlot + 22
          : bottomOfPlot + 40,
      bottomOfPlot,
    },
  };
}

export function lineGenerator(data, xAccessor, yAccessor, xScale, yScale) {
  return d3
    .line()
    .defined((d) => !isNaN(d.value) && d.value != null)
    .x((d) => xScale(xAccessor(d)))
    .y((d) => yScale(yAccessor(d)))(data);
}

export function areaGenerator(
  data,
  xAccessor,
  yAccessor,
  xScale,
  yScale,
  variable,
  isCurrentsOrTidesStation,
  dimensions
) {
  return d3
    .area()
    .defined((d) => !isNaN(d.value) && d.value != null)
    .curve(
      (variable === 'wave' || variable === 'seaSurfaceHeight' || variable === 'wind') &&
        d3.min(data, yAccessor) === 0
        ? d3.curveMonotoneX
        : d3.curveNatural
    )
    .x((d) => xScale(xAccessor(d)))
    .y0(
      isCurrentsOrTidesStation
        ? dimensions.height - dimensions.margin.bottom - dimensions.margin.bottomOfPlot
        : yScale(0)
    )
    .y1((d) => yScale(yAccessor(d)))(data);
}

export function multiFormat(date) {
  const formatMillisecond = d3.timeFormat('.%L');
  const formatSecond = d3.timeFormat(':%S');
  const formatMinute = d3.timeFormat('%I:%M');
  const formatHour = d3.timeFormat('%-I %p');
  const formatDay = d3.timeFormat('%b %e');
  const formatWeek = d3.timeFormat('%A %e');
  const formatMonth = d3.timeFormat('%b %e');
  const formatYear = d3.timeFormat('%Y');

  return (
    d3.timeSecond(date) < date
      ? formatMillisecond
      : d3.timeMinute(date) < date
      ? formatSecond
      : d3.timeHour(date) < date
      ? formatMinute
      : d3.timeDay(date) < date
      ? formatHour
      : d3.timeMonth(date) < date
      ? d3.timeWeek(date) < date
        ? formatWeek
        : formatWeek
      : d3.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

export function getSunData({ data, pointLocations }) {
  const formatYMD = d3.timeFormat('%Y-%m-%d');
  const alluniqdays = [...new Set(data.map((d) => formatYMD(new Date(d.date))))];

  const days = alluniqdays.map((d) => {
    d = new Date(d);
    d.setHours(d.getHours() + 12);
    return d;
  });
  return days
    .map((day) => ({
      day,
      sun: suncalc.getTimes(day, pointLocations[0], pointLocations[1]),
    }))
    .map(({ day, sun }) => {
      return {
        ...sun,
        day,
        localSunrise: new Date(sun.sunrise.getTime()),
        localSunset: new Date(sun.sunset.getTime()),
        localDawn: new Date(sun.dawn.getTime()),
        localDusk: new Date(sun.dusk.getTime()),
      };
    });
}

export function normalizeDatasetTime(data) {
  const diffHourly = data.filter((d) => !d.isLive);
  const diff = diffHourly
    .filter((e, i) => i < diffHourly.length - 1)
    .map((d, i) => {
      return i === 0
        ? {
            difference: Math.floor((diffHourly[i + 1].date - d.date) / 1000 / 60),
            time: d.date,
          }
        : {
            difference: Math.floor((diffHourly[i + 1].date - d.date) / 1000 / 60),
            time: diffHourly[i + 1].date,
          };
    })
    .filter((d) => d.difference !== 0);
  const completeTimeSeries =
    diffHourly.length === 1
      ? []
      : d3.utcMinute
          .every(d3.min(diff, (d) => d.difference))
          .range(new Date(data[0].date), new Date(data[data.length - 1].date));

  // range is exlusive of the end time, so add it back
  completeTimeSeries.push(new Date(data[data.length - 1].date));

  const completeDataset = completeTimeSeries.map((d) => {
    const matched = data.find((f) => +f.date === +d);
    return matched === undefined
      ? {
          date: d,
        }
      : matched;
  });

  return completeDataset;
}

export function pulse(circle) {
  (function repeat() {
    circle
      .transition()
      .duration(500)
      .attr('stroke-width', 0)
      .attr('stroke-opacity', 0)
      .transition()
      .duration(500)
      .attr('stroke-width', 0)
      .attr('stroke-opacity', 0.5)
      .transition()
      .duration(1000)
      .attr('stroke-width', 65)
      .attr('stroke-opacity', 0)
      .ease(d3.easeSin)
      .on('end', repeat);
  })();
}

export function getSubVariables(data, variable) {
  let variablesReturned = Object.keys(Object.assign({}, ...data)).sort(d3.descending);

  if (
    variablesReturned.includes('direction') &&
    data.filter((d) => !isNaN(d.direction) && d.direction != null).length === 0
  ) {
    variablesReturned = variablesReturned.filter((e) => e !== 'direction');
  }
  return {
    [variable]: variablesReturned.filter(
      (d) => d === 'value' || d === 'direction' || d === 'period' || d === 'gust'
    ),
  };
}

export function makeClipPath() {
  let clipCount = 0;
  function uid(name) {
    return new Id('O-' + (name == null ? '' : name + '-') + ++clipCount);
  }
  function Id(id) {
    this.id = id;
    this.href = new URL(`#${id}`, location) + '';
  }
  Id.prototype.toString = function () {
    return 'url(' + this.href + ')';
  };
  return uid('clip');
}
