import React from 'react';
import { Stack, Grid, Box, Typography } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';

import {
  formatAMPM,
  formatAMPMwithMinutes,
  formatNumberGenerator,
  daysOfTheWeekShortName,
  getArrowDirection,
} from '../../utilities.js';

function VariableInsightBottom({
  currentInsightsData,
  variableData,
  selectedVariableName,
  formatNumber,
  unit,
  formatHourMin,
  selectedStation,
  settings,
}) {
  const nextHigh = currentInsightsData?.find((d) => d.change === 'high');
  const nextLow = currentInsightsData?.find((d) => d.change === 'low');
  const nextSlack = currentInsightsData?.find((d) => d.peak_slack === 'slack');
  const nextFlood = currentInsightsData?.find((d) => d.flood_ebb === 'flood');
  const nextEbb = currentInsightsData?.find((d) => d.flood_ebb === 'ebb');

  let arrowDirection;
  if (variableData?.secondary_direction !== undefined) {
    arrowDirection = getArrowDirection(
      selectedVariableName,
      variableData?.secondary_direction
    );
  }

  function generateEventTime(time) {
    return `${formatHourMin(new Date(time))}, ${
      daysOfTheWeekShortName[new Date(time).getDay()]
    } ${new Date(time).getDate()}`;
  }
  function generateStack(label, event) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        width="100%"
      >
        <Typography variant="caption">{label}</Typography>
        <Typography variant="caption">
          {event?.value !== undefined ? `${formatNumber(event?.value)} ${unit}` : ''}
        </Typography>
        <Typography variant="caption">
          {event?.date ? `${generateEventTime(event?.date)}` : ''}
        </Typography>
      </Stack>
    );
  }

  function generateSwellStack(variableData) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        width="100%"
      >
        <Box width={1 / 3} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" align="center">
            {'Secondardy swell:'}
          </Typography>
        </Box>
        <Box width={1 / 3} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" align="center">
            {formatNumber(variableData?.secondary_height)}
            {formatNumber(variableData?.secondary_height) === 'no data'
              ? ' '
              : ' ' + unit}
          </Typography>
        </Box>
        <Box width={1 / 3} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" align="center">
            {formatNumber(variableData?.secondary_height) === 'no data'
              ? ''
              : `${variableData?.secondary_period?.toFixed(0)}s`}
          </Typography>
        </Box>
        {arrowDirection != null && typeof arrowDirection === 'number' && (
          <ArrowUpward
            color="secondary"
            sx={{
              transform: `rotate(${arrowDirection}deg)`,
              width: '30px',
              height: '30px',
            }}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack justifyContent="space-evenly" alignItems="center">
      {selectedVariableName === 'seaSurfaceHeight' ? (
        <>
          {generateStack('Next high tide:', nextHigh)}
          {generateStack('Next low tide:', nextLow)}
        </>
      ) : selectedVariableName === 'wave' ? (
        <>{generateSwellStack(variableData)}</>
      ) : (
        <>
          {generateStack('Next slack:', nextSlack)}
          {generateStack('Next peak flood:', nextFlood)}
          {generateStack('Next peak ebb:', nextEbb)}
        </>
      )}
    </Stack>
  );
}

export default VariableInsightBottom;
