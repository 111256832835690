import airTemp from './ColorScales/airTemp.json';
import currents from './ColorScales/currents.json';
import tides from './ColorScales/tides.json';
import waterTemp from './ColorScales/waterTemp.json';
import waves from './ColorScales/waves.json';
import wind from './ColorScales/wind.json';
import { metersPerSecondToKMPerHour } from './utilities';

const mapLayers = [
  {
    displayName: 'Sea Temperature',
    variableName: 'potentialTemperature',
    icon: 'ocean.temperature.svg',
    model: 'CIOPS',
    modelLongName: 'CIOPS-SalishSea',
    isVector: false,
    unit: 'c',
    scale: waterTemp.reverse(),
    maxzoom: 9,
    isWaterVariable: true,
  },
  {
    displayName: 'Waves',
    variableName: 'wave',
    model: 'RDWPS',
    modelLongName: 'RDWPS',
    isVector: true,
    unit: 'm',
    icon: 'waves.svg',
    scale: waves.reverse(),
    vectorSpeed: 1,
    maxzoom: 7,
    isWaterVariable: true,
  },

  {
    displayName: 'Air Temperature',
    variableName: 'airTemperature',
    model: 'HRDPS',
    modelLongName: 'HRDPS',
    isVector: false,
    unit: 'c',
    icon: 'air.temperature.svg',
    scale: airTemp.reverse(),
    maxzoom: 7,
    isWaterVariable: false,
  },
  {
    displayName: 'Currents',
    variableName: 'current',
    model: 'CIOPS',
    modelLongName: 'CIOPS-SalishSea',
    isVector: true,
    unit: 'kmh',
    icon: 'currents.svg',
    scale: currents.reverse().map((step) => {
      return { color: step.color, value: metersPerSecondToKMPerHour(step.value) };
    }),
    vectorSpeed: 0.75,
    maxzoom: 9,
    isWaterVariable: true,
  },
  {
    displayName: 'Wind',
    variableName: 'wind',
    model: 'HRDPS',
    modelLongName: 'HRDPS',
    isVector: true,
    unit: 'kmh',
    icon: 'wind.svg',
    scale: wind.reverse().map((step) => {
      return { color: step.color, value: metersPerSecondToKMPerHour(step.value) };
    }),
    vectorSpeed: 1,
    maxzoom: 7,
    isWaterVariable: false,
  },
  {
    displayName: 'Tides',
    variableName: 'seaSurfaceHeight',
    model: 'CIOPS',
    modelLongName: 'CIOPS-SalishSea',
    isVector: false,
    unit: 'm',
    icon: 'tides.svg',
    scale: tides.reverse().map((step) => {
      return { ...step, value: step.value + 2.438 };
    }),
    maxzoom: 9,
    isWaterVariable: true,
  },
];

export default mapLayers;
