import React from 'react';
import { Stack, Typography, Backdrop, Box, Link, Button, useTheme } from '@mui/material';
import { VerticalTrademark } from './VerticalTrademark.jsx';
import Spinner from './Spinner.jsx';

export default function Welcome({
  cookieConsent,
  ocTermsAgreement,
  cookiesChecked,
  welcomeClosed,
  setCookieConsent,
  setOCTermsAgreement,
  setWelcomeClosed,
}) {
  const theme = useTheme();

  function welcomeSteps() {
    if (!welcomeClosed) {
      //
      return (
        <Stack alignItems="center" spacing={1.5}>
          <Typography variant="h4" color="secondary">
            Welcome to
          </Typography>
          <Box sx={{ width: '60%' }}>
            <VerticalTrademark />
          </Box>
          <Stack alignItems="center">
            <Typography color="secondary" variant="h6">
              Marine forecasts &amp;
            </Typography>
            <Typography color="secondary" variant="h6">
              Current conditions
            </Typography>
          </Stack>
          <Button
            variant="contained"
            color="success"
            onClick={() => setWelcomeClosed(true)}
          >
            Dive in
          </Button>
        </Stack>
      );
    } else if (cookiesChecked && ocTermsAgreement === undefined) {
      //
      return (
        <>
          <Typography variant="h6" color="secondary">
            Non-navigational use
          </Typography>
          <Typography color="secondary" variant="caption">
            OceanConnect&trade; is for <b>non-navigational</b> use only. Please review our{' '}
            <Link href="/docs/data_sources/" color="secondary">
              Data Sources
            </Link>{' '}
            page for more information concerning the limitations of our data sources.
          </Typography>
          <Button
            variant="contained"
            color="success"
            onClick={() => setOCTermsAgreement(true)}
          >
            I understand
          </Button>
        </>
      );
    } else if (cookiesChecked && cookieConsent === undefined) {
      return (
        <>
          <Typography variant="h6" color="secondary">
            Cookie consent
          </Typography>
          <Typography color="secondary" variant="caption">
            OceanConnect&trade; uses cookies to enhance your user experience and improve
            the quality of our site. For more detailed information on the cookies we use,
            please visit our{' '}
            <Link href="/docs/privacy/" color="secondary">
              Privacy Policy
            </Link>
            .
          </Typography>
          <Stack direction="row" spacing={3}>
            <Button
              variant="contained"
              color="success"
              onClick={() => setCookieConsent(true)}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => setCookieConsent(false)}
            >
              Deny
            </Button>
          </Stack>
        </>
      );
    }
  }

  return (
    <Backdrop
      open={cookieConsent === undefined || ocTermsAgreement === undefined}
      sx={{
        zIndex: 2000,
        background: `linear-gradient(rgba(2, 53, 85, 0.9), rgba(2, 53, 85, 0.5))`,
      }}
    >
      <Stack
        alignItems="center"
        spacing={3}
        sx={{ width: '30%', minWidth: '200px', maxWidth: '300px' }}
      >
        {welcomeSteps()}
      </Stack>
    </Backdrop>
  );
}
