import React from 'react';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';

import { degToCompass, getArrowDirection } from '../../utilities.js';
import TideIndicator from '../TideIndicator.jsx';

function VariableInsightDisplay({
  variableData,
  selectedVariableName,
  currentInsightsData,
  formatNumber,
  unit,
  liveData,
  scrollDate,
  tideStationMetadata,
}) {
  const theme = useTheme();
  let arrowDirection, direction;
  if (liveData?.isLive === true && +liveData?.date === +scrollDate) {
    direction = liveData?.direction;
    if (liveData?.direction !== undefined) {
      arrowDirection = getArrowDirection(selectedVariableName, liveData?.direction);
    }
  } else {
    direction = variableData?.direction;
    if (variableData?.direction !== undefined) {
      arrowDirection = getArrowDirection(selectedVariableName, variableData?.direction);
    }
  }
  switch (selectedVariableName) {
    case 'wave':
      return (
        <React.Fragment>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', height: '50%' }}
            spacing={0}
            paddingTop="15px"
          >
            {direction != null &&
              typeof direction === 'number' &&
              arrowDirection != null &&
              typeof arrowDirection === 'number' && (
                <ArrowUpward
                  color="secondary"
                  sx={{
                    transform: `rotate(${arrowDirection}deg)`,
                    width: '40px',
                    height: '40px',
                  }}
                />
              )}
          </Stack>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
              period
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {variableData?.period?.toFixed(0)}s
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case 'airTemperature':
    case 'potentialTemperature':
      return (
        <React.Fragment>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
              high
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ borderBottom: `2px solid ${theme.palette.secondary.main}` }}
          >
            <Stack direction="row">
              <Typography
                color="secondary"
                sx={{ textTransform: 'none' }}
                variant="subtitle1"
              >
                {formatNumber(currentInsightsData?.high)}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ padding: '0px 0px 5px 5px', textTransform: 'none' }}
              >
                {unit}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
              low
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row">
              <Typography
                color="secondary"
                sx={{ textTransform: 'none' }}
                variant="subtitle1"
              >
                {formatNumber(currentInsightsData?.low)}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ padding: '0px 0px 5px 5px', textTransform: 'none' }}
              >
                {unit}
              </Typography>
            </Stack>
          </Grid>
        </React.Fragment>
      );
    case 'current':
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%', height: '100%' }}
          spacing={2}
          paddingTop="15px"
        >
          {' '}
          {direction != null &&
            direction != null &&
            typeof direction === 'number' &&
            arrowDirection != null &&
            typeof arrowDirection === 'number' && (
              <ArrowUpward
                color="secondary"
                sx={{
                  transform: `rotate(${String(arrowDirection)}deg)`,
                  width: '40px',
                  height: '40px',
                }}
              />
            )}
          {/* <Typography variant="caption" >{degToCompass(variableData?.direction)}</Typography> */}
        </Stack>
      );
    case 'seaSurfaceHeight':
      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          sx={{ width: '100%', height: '100%', paddingTop: '25px' }}
        >
          {
            <TideIndicator
              minTide={tideStationMetadata?.min_tide}
              maxTide={tideStationMetadata?.max_tide}
              currentTide={variableData?.value}
            />
          }
          <Typography variant="body">{variableData?.change}</Typography>
        </Stack>
      );
    case 'wind':
      return (
        <React.Fragment>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', height: '50%' }}
            spacing={0}
            paddingTop="15px"
          >
            {direction != null &&
              typeof direction === 'number' &&
              arrowDirection != null &&
              typeof arrowDirection === 'number' && (
                <ArrowUpward
                  color="secondary"
                  sx={{
                    transform: `rotate(${arrowDirection}deg)`,
                    width: '40px',
                    height: '40px',
                  }}
                />
              )}
          </Stack>

          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
              gust
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row">
              <Typography
                color="secondary"
                sx={{ textTransform: 'none', textAlign: 'center' }}
                variant="subtitle1"
              >
                {liveData?.isLive === true && +liveData?.date === +scrollDate
                  ? formatNumber(liveData?.gust)
                  : formatNumber(variableData?.gust)}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ padding: '0px 0px 5px 5px', textTransform: 'none' }}
              >
                {variableData?.gust !== undefined && variableData?.gust !== null && unit}
              </Typography>
            </Stack>
          </Grid>
        </React.Fragment>
      );
    default:
      break;
  }
}

export default VariableInsightDisplay;
