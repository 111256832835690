import { Box, useTheme } from '@mui/material';
import React from 'react';

export default function TideIndicator({
  maxTide,
  minTide,
  currentTide,
  height = '30px',
  width = '20px',
}) {
  const theme = useTheme();
  const minMaxDifference = maxTide - minTide;
  let normCurrentTide = currentTide - minTide;
  if (normCurrentTide <= 0) {
    normCurrentTide = minTide;
  } else if (normCurrentTide >= maxTide) {
    normCurrentTide = maxTide;
  }
  const currentTidePercent = (normCurrentTide / minMaxDifference) * 100;
  return (
    <Box
      component="img"
      sx={{
        height,
        width,
        background: `linear-gradient(to top, ${theme.palette.primary.main} ${currentTidePercent}%, ${theme.palette.secondary.main} ${currentTidePercent}%)`,
        borderRadius: '3px',
        border: `1px ${theme.palette.primary.dark} solid`,
      }}
    />
  );
}
