import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios from 'axios';

import { server } from '../utilities';
import { Send } from '@mui/icons-material';

export default function MailchimpForm() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [email, setEmail] = useState('');
  const [previousEmail, setPreviousEmail] = useState('');
  const emailStates = [
    {
      state: 'empty',
      color: 'secondary',
      responseMessage: '',
      message: 'Please enter your preferred e-mail address.',
    },
    {
      state: 'sending',
      color: 'secondary',
      responseMessage: '',
      message: 'Sending...',
    },
    {
      state: 'emailSubmitted',
      color: 'secondary',
      responseMessage: 'thank you for subscribing',
      message: 'Thank you for subscribing!',
    },
    {
      state: 'emailError',
      color: theme.palette.warning.main,
      responseMessage: 'an unexpected internal error has occurred',
      message: 'An unexpected internal error has occurred.',
    },
    {
      state: 'emailSubmittedTooManyTimes',
      color: theme.palette.warning.main,
      responseMessage: 'has too many recent signup requests',
      message: 'Too many subscribe attempts for this email address',
    },
    {
      state: 'emailAlreadySubmitted',
      color: theme.palette.warning.main,
      responseMessage: 'already subscribed',
      message: 'This email is already subscribed to the newsletter.',
    },
    {
      state: 'emailInvalid',
      color: theme.palette.warning.main,
      responseMessage: 'please enter a valid email address',
      message: 'Please enter a valid email address.',
    },
  ];
  const [emailState, setEmailState] = useState(emailStates[0]);
  const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;

  function parseJsonpResponse(response) {
    // Split the response into separate JSONP responses
    const jsonpResponses = response.split(')__jp0(');

    // Remove the '__jp0(' at the beginning of the first response
    jsonpResponses[0] = jsonpResponses[0].replace(/^__jp0\(/, '');

    // Remove the ')' at the end of the last response
    const lastIndex = jsonpResponses.length - 1;
    jsonpResponses[lastIndex] = jsonpResponses[lastIndex].replace(/\)$/, '');

    // Parse each JSON string and return the resulting objects in an array
    const data = jsonpResponses.map((jsonStr) => {
      try {
        return JSON.parse(jsonStr);
      } catch (error) {
        console.error('Error parsing JSONP response:', error);
      }
    });

    return data;
  }

  function submitEmail() {
    setEmailState(emailStates[1]);
    axios
      .get(server + `/newsletter-subscribe?email=${email}`)
      .then((response) => {
        const data = parseJsonpResponse(response.data);
        const error = data.filter((item) => item.result === 'error');
        if (error.length > 0) {
          const errorType = error[0].msg;
          if (errorType.includes('already subscribed')) {
            setEmailState(emailStates[5]);
          } else if (
            errorType.includes('invalid') ||
            errorType.includes('email cannot be added to this list')
          ) {
            setEmailState(emailStates[6]);
          } else if (errorType.includes('many subscribe attempts')) {
            setEmailState(emailStates[4]);
          } else {
            setEmailState(emailStates[3]);
          }
        } else {
          if (data[0].result === 'success') {
            setEmailState(emailStates[2]);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setEmailState(emailStates[3]);
      });
  }

  function generateSubmitButton() {
    return (
      <Grid item container md={2}>
        <Tooltip title="Subscribe to the OceanConnect newsletter">
          <Stack alignItems="center" spacing={0.2}>
            <IconButton
              disabled={
                !regex.test(email) ||
                emailState.state === 'sending' ||
                previousEmail === email
              }
              onClick={() => {
                setEmailState(emailStates[0]);
                submitEmail();
                setPreviousEmail(email);
              }}
              sx={{
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.primary.light,
                textTransform: 'none',
                borderRadius: '25px',
                height: '38px',
                width: '84px',
                textAlign: 'center',
                paddingTop: '8px',
                border: '2px transparent solid',
                '&:hover': {
                  border: `2px ${theme.palette.secondary.main} solid`,
                },
                '&:disabled': {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.secondary.main,
                },
              }}
              variant="contained"
            >
              <Send
                sx={{
                  color: !regex.test(email)
                    ? theme.palette.primary.light
                    : theme.palette.secondary.main,
                }}
              />
            </IconButton>
            <Typography variant="caption">Subscribe</Typography>
          </Stack>
        </Tooltip>
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        padding: '20px',
      }}
      columnSpacing={1}
      rowSpacing={1}
    >
      <Grid item container md={10}>
        <TextField
          color="primary"
          required
          variant="filled"
          placeholder="Email"
          fullWidth
          value={email}
          error={email !== '' && !regex.test(email)}
          helperText={
            email !== '' && !regex.test(email) && 'Please enter a valid email address.'
          }
          onChange={(e) => {
            setEmailState(emailStates[0]);
            setEmail(e.target.value);
            setPreviousEmail('');
          }}
          inputProps={{
            style: {
              color: theme.palette.secondary.main,
              padding: '15px 20px 10px 20px',
              width: '100%',
              textAlign: 'left',
            },
          }}
        />
      </Grid>
      {!mobileView && generateSubmitButton()}
      <Grid item container xs={12}>
        <Typography fontSize="16px" color={emailState.color} sx={{ width: '500px' }}>
          {emailState.message}
        </Typography>
      </Grid>
      {mobileView && generateSubmitButton()}
    </Grid>
  );
}
